import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import axios from 'axios';

import logo from 'assets/images/logo.png';

import 'core-js';

class Preloader extends Component {

  state = {
    loading: true,
    newsData: undefined,
    siteData: undefined,
  }

  onSuccess = (newsData, siteData) => {

    this.setState({
      loading: false,
      newsData,
      siteData,
    })

    setTimeout(() => {
      ReactDOM.render(<App siteData={this.state.siteData} newsData={this.state.newsData}/>, document.getElementById('root'));
      registerServiceWorker();
    }, 500);

  };

  onError = (error) => {
    console.log('there was an error loading data', error);
  };

  componentWillMount() {
    axios.all([axios.get('https://api.wjlaw.com.au/'), axios.get('https://api.wjlaw.com.au/wp-json/wp/v2/posts?_embed')])
      .then(axios.spread((newsResponse, siteResponse) => {
        this.onSuccess(siteResponse.data, newsResponse.data);
      }))
      .catch(this.onError);
  }

  render() {
    return (
      <div className={`preloader-container ${(!this.state.loading) ? 'loaded' : ''}`}>
        <img src={logo} alt="logo"/>
        <div className="spinner">
          <div className="bounce1"></div>
          <div className="bounce2"></div>
          <div className="bounce3"></div>
        </div>
      </div>
    );
  }
}

ReactDOM.render(<Preloader/>, document.getElementById('preloader'));
