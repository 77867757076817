import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import ArrowIcon from 'assets/icons/ArrowIcon';

class AnimatedLink extends Component {

  linkContent = () => {
    return (
     <div className="animated-link">
       <span className="animated-link__text">{this.props.text}</span>
       <div className="animated-link__animated-arrow">
         <span className="animated-link__dot animated-link__dot--first"></span>
         <span className="animated-link__dot"></span>
         <span className="animated-link__dot animated-link__dot--last"></span>
         <ArrowIcon className="animated-link__arrow"/>
       </div>
     </div>
    );
  }

  render() {

    const {...rest } = this.props;

    const link = (this.props.to) ? (
      <Link to={this.props.to} className={this.props.className} {...rest}>{this.linkContent()}</Link>
    ) : (this.props.href) ? (
      <a href={this.props.href} className={this.props.className} {...rest}>{this.linkContent()}</a>
    ) : (
      <div className={this.props.className} {...rest}>{this.linkContent()}</div>
    );

    return (link);
  }
}

export default AnimatedLink;
