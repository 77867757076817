import React, { Component } from 'react';
import withHelmet from '../../withHelmet';

import lion from 'assets/images/commercial-litigation-lion.jpg';
import lion1 from 'assets/images/insolvency-restructuring-lion.jpg';
import AnimatedLink from 'components/atoms/animated-link/AnimatedLink';

import AnimationHelper from 'helpers/js/AnimationHelper';
import ScrollMagic from 'scrollmagic';

class Work extends Component {

  renderHTMLContent = (content) => {
    return { __html: content};
  }

  componentDidMount() {
    AnimationHelper.init(new ScrollMagic.Controller(), this);
  }

  render() {

    const { title, introduction_text, our_experience, recent_experiences = [], name, portfolio_file } = this.props.data;

    const recentExperiences = ( recent_experiences && recent_experiences.map((element, index) => (
      <div className="work__experience__box with-transitions" key={index} dangerouslySetInnerHTML={this.renderHTMLContent(element.recent_experience)}></div>
    )));

    const recentExperiencesSection = (
      <div className="animated-container">
        <h3 className="work__experience__header with-transitions with-transitions">a sample of recent experience</h3>
        <div className="work__experience-wrapper">
          {recentExperiences}
          <div className="work__experience__box work__experience__box--extra">
            <p className="with-transitions">Looking for more? Browse our extensive commercial litigation experience here.</p>
            <AnimatedLink className="with-transitions" href={portfolio_file ? portfolio_file.url : undefined} target="_blank" text="download"/>
          </div>
        </div>
      </div>
    );

    const lionToRender = (name === 'commercial-litigation') ? lion : lion1;

    return (
      <section className={`work ${(name !== 'commercial-litigation') ? 'dark' : ''}`}>
        <div className="background-lines">
          <div className="background-line background-line--1"></div>
          <div className="background-line background-line--2"></div>
          <div className="background-line background-line--3"></div>
        </div>

        <div className="top-content">
          <div className="animated-container">
            <h1 className="work__title with-transitions">{title}</h1>
          </div>
          <div className="work__intro-wrapper animated-container">
            <div className="work__intro-left-col">
              <img src={lionToRender} alt="lion" className="lion"/>
              <div className="work__intro__text" dangerouslySetInnerHTML={this.renderHTMLContent(introduction_text)}></div>
            </div>
            <div className="work__intro-right-col">
              <div className="work__intro__image">
                <img src={lionToRender} alt="work intro"/>
              </div>
              <h3 className="work__intro__header with-transitions">our experience</h3>
              <div className="work__intro__text" dangerouslySetInnerHTML={this.renderHTMLContent(our_experience)}></div>
            </div>
          </div>
        </div>
        {recent_experiences && recentExperiencesSection}
      </section>
    )
  }
}

export default withHelmet(Work);
