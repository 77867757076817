import React, { Component } from 'react';
import withHelmet from "../../withHelmet";

import AnimatedLink from 'components/atoms/animated-link/AnimatedLink';
import { Route, withRouter, Link } from 'react-router-dom';

import ScrollMagic from 'scrollmagic';

import AnimationHelper from 'helpers/js/AnimationHelper';
import Overlay from 'components/pages/people/Overlay';

const renderMergedProps = (component, ...rest) => {
  const finalProps = Object.assign({}, ...rest);
  return (
    React.createElement(component, finalProps)
  );
}

const PropsRoute = ({ component, ...rest }) => {
  return (
    <Route {...rest} render={routeProps => {
      return renderMergedProps(component, routeProps, rest);
    }}/>
  );
}

class People extends Component {

  componentDidMount() {
    this.controller = new ScrollMagic.Controller();
    AnimationHelper.init(this.controller, this);
  }

  componentWillUnmount() {
    this.controller.destroy();
  }

  renderHTMLContent = (content) => {
    return { __html: content};
  }

  render() {

    const { core_values = [], subtitle, introduction_text, our_team = []} = this.props.data;

    const coreValuesNodes = core_values.map((element, index) => (
      <div key={index}>
        <div className="people__core-values__title with-transitions"><span>0{index + 1}</span><span>{element.title}</span></div>
        <div className="people__core-values__value with-transitions">{element.text}</div>
      </div>
    ));

    const peopleGridNodes = this.props.team.map((element, index) => (
      <Link className="people__grid__item with-transitions" key={index} to={`/people-and-culture/${element.ID}`}>
        <div className="people__grid__item__image" >
          <img src={element.profile_picture.sizes.medium} alt=""/>
        </div>
        <div className="people__grid__item__content">
          <p className="people__grid__item__name">{element.title}</p>
          <p className="people__grid__item__position">{element.designation}</p>
          <AnimatedLink text="" />
        </div>
      </Link>
    ));

    return (
      <section className="people">
        <PropsRoute path={`/people-and-culture/:id`} exact={true} component={Overlay} data={this.props.team}/>
        <div className="background-lines">
          <div className="background-line background-line--1"></div>
          <div className="background-line background-line--2"></div>
          <div className="background-line background-line--3"></div>
        </div>
        <div className="people__dark-background">
          <div className="people__header animated-container">
            <h1 className="with-transitions">{subtitle}</h1>
            <p className="with-transitions">{introduction_text}</p>
          </div>
          <div className="people__main-image-container">
            <div className="people__main-image"></div>
          </div>
          <div className="people__careers animated-container">
            <h2 className="with-transitions">Careers</h2>
            <p className="with-transitions">If our values ring true then please get in touch with us.</p>
            <AnimatedLink to="/join-the-team" text="apply now" className="with-transitions" />

            <div className="people__core-values animated-container">
              <div className="people__core-values__header">
                <p className="with-transitions">William James</p>
                <h3 className="with-transitions">Core values</h3>
              </div>
              {coreValuesNodes}
            </div>
          </div>
        </div>
        <div className="people__team animated-container">
          <h2 className="with-transitions">our team</h2>
          <div className="people__team__box-1" dangerouslySetInnerHTML={this.renderHTMLContent(our_team.length && our_team[0].content_box)}></div>
          <div className="people__team__box-2">
            <div dangerouslySetInnerHTML={this.renderHTMLContent(our_team.length && our_team[1].content_box)}></div>
            <AnimatedLink to="/join-the-team" text="do you have what it takes to join us" className="with-transitions"/>
          </div>
        </div>
        <div className="people__grid animated-container">{peopleGridNodes}</div>
      </section>
    )
  }
}

export default withHelmet(withRouter(People));
