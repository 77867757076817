import React, { Component } from 'react';
import ScrollMagic from 'scrollmagic';
import moment from 'moment';

import withHelmet from '../../withHelmet';

import AnimatedLink from 'components/atoms/animated-link/AnimatedLink';
import AnimationHelper from 'helpers/js/AnimationHelper';

class NewsOverview extends Component {

  componentDidMount() {
    this.controller = new ScrollMagic.Controller();
    AnimationHelper.init(this.controller, this);
  }

  componentWillUnmount() {
    this.controller.destroy();
  }

  getPostThumbnail(news) {
    const media = (news && news._embedded['wp:featuredmedia']);
    if (media) {
      const image = media[0];
      return {
        src: image.media_details.sizes.full.source_url,
        alt: image.alt_text,
      }
    }

    return false;
  }

  renderNewsTeaser(data, index) {

    const thumbnail = this.getPostThumbnail(data);

    return (
      <article className="news-teaser" key={index}>
        {thumbnail &&
        <div className="news-teaser__image">
          <img src={thumbnail.src} alt={thumbnail.alt}/>
        </div>}
        <h1 className="news-teaser__title">{data.title.rendered}</h1>
        <div className="news-teaser__date">{moment(data.date).format('Do MMMM YYYY')}</div>
        <p className="news-teaser__excerpt"  dangerouslySetInnerHTML={{__html: data.excerpt.rendered}}></p>
        <AnimatedLink text="read more" to={`/news/${data.slug}`}/>
      </article>
    );
  }

  render() {

    const newsList = this.props.data.map((element, index) => {
      return this.renderNewsTeaser(element, index);
    });

    return (
      <section className="news-overview">
        <div className="background-lines">
          <div className="background-line background-line--1"></div>
          <div className="background-line background-line--2"></div>
          <div className="background-line background-line--3"></div>
        </div>
        <div className="animated-container">
          <h1 className="with-transitions news-overview__title">news & updates</h1>
        </div>
        <div className="animated-container news-overview__teaser-wrapper">
          {newsList}
        </div>

      </section>
    )
  }
}

export default withHelmet(NewsOverview);
