import React from 'react';
import Helmet from 'react-helmet';

function withHelmet(WrappedComponent) {
  return class extends React.Component {

    render() {
      const keys = [
        { name: "title", api_prop: "meta_title" },
        { name: "description", api_prop: "meta_description" },
        { name: "keywords", api_prop: "meta_keywords" }
      ];

      let Meta = keys.map((key) => {
        if(this.props.data && this.props.data[key.api_prop]) {
          return <meta key={key.name} name={key.name} content={this.props.data[key.api_prop]} />;
        }
        return null;
      });

      Meta = Meta.filter(Boolean);

      return (
        <div>
          { Meta.length > 0 ?
            <Helmet>
              {Meta}
              { this.props.data.meta_title ?
                <title>{this.props.data.meta_title}</title>
              : null }
            </Helmet>
          : null }
          <WrappedComponent {...this.props} />
        </div>
      );
    }
  }
}



export default withHelmet;
