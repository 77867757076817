import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Link,
  withRouter,
  Switch,
} from 'react-router-dom';

import logo from './assets/images/logo.png';
import logoHover from './assets/images/logo_hover.png';
import logoWhite from './assets/images/logo_white.png';

import Homepage from 'components/pages/homepage/Homepage';
import About from 'components/pages/about/About';
import Contact from 'components/pages/contact/Contact';
import PrivacyPolicy from 'components/pages/privacy-policy/PrivacyPolicy';
import Navigation from './components/molecules/navigation/Navigation';
import Work from './components/pages/work/Work';
import People from './components/pages/people/People';
import Apply from './components/pages/apply/Apply';
import ApplicationSent from './components/pages/apply/ApplicationSent';

import NewsOverview from './components/pages/news/news-overview';
import NewsSingle from './components/pages/news/news-single';

import BodyScroll from 'helpers/js/BodyScroll';

import { TweenMax, TimelineMax } from 'gsap';

const renderMergedProps = (component, ...rest) => {
  const finalProps = Object.assign({}, ...rest);
  return React.createElement(component, finalProps);
};

const PropsRoute = ({ component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(routeProps) => {
        return renderMergedProps(component, routeProps, rest);
      }}
    />
  );
};

const findPageBySlug = (data, slug) => {
  return data ? data.pages.find((element) => element.name === slug) : {};
};

const getTheTeam = (data) => {
  return data ? data.team : [];
};

const AppWithRouter = withRouter(
  class AppContainer extends Component {
    state = {
      footerMenu: [],
      mainMenu: [],
      dataLoaded: false,
      navActive: false,
    };

    constructor(props) {
      super(props);

      this.bodyScroll = new BodyScroll();
    }

    navToggle = (event) => {
      const checked = event.target.checked;

      this.setState((prevState, props) => {
        return { navActive: checked };
      });

      this.bodyScroll.toggleFreeze(checked);
    };

    componentWillMount() {
      const { footer, main } = this.props.siteData.menus;

      this.setState({
        footerMenu: footer,
        mainMenu: main,
        siteData: this.props.siteData,
        newsData: this.props.newsData,
        dataLoaded: true,
        componentToRender: undefined,
      });
    }

    componentDidUpdate(prevProps) {
      if (this.props.location !== prevProps.location) {
        this.onRouteChanged();
      }
    }

    onRouteChanged() {
      if (this.state.navActive) {
        this.bodyScroll.toggleFreeze(false);
        this.setState({
          navActive: false,
        });
      }

      TweenMax.set(window, { scrollTo: { y: 0 } });
    }

    onMouseOver = () => {
      // this.hamburgerAnimation.play();

      if (this.state.navActive) {
        return;
      }

      this.hamburgerAnimation = new TimelineMax();

      const l1 = '#hamburger-first-line';
      const l2 = '#hamburger-second-line';
      const l3 = '#hamburger-third-line';

      this.hamburgerAnimation.to(l1, 0.2, { x: 6, width: 16 });
      this.hamburgerAnimation.to(l2, 0.3, { width: 14, x: 8 }, '-=0.1');
      this.hamburgerAnimation.to(l2, 0.2, { width: 24, x: 3 });
      this.hamburgerAnimation.to(l3, 0.2, { width: 16, x: -6 }, '-=0.3');
      this.hamburgerAnimation.to(l3, 0.2, { x: 0 }, '-=0.2');
    };

    onMouseLeave = () => {
      //  this.hamburgerAnimation.reverse();

      if (this.state.navActive) {
        return;
      }

      this.hamburgerAnimation = new TimelineMax();

      const l1 = '#hamburger-first-line';
      const l2 = '#hamburger-second-line';
      const l3 = '#hamburger-third-line';

      this.hamburgerAnimation.to(l1, 0.2, { width: 18, x: 0 });
      this.hamburgerAnimation.to(l2, 0.2, { width: 24, x: 0 }, '-=0.2');
      this.hamburgerAnimation.to(l3, 0.2, { width: 18, x: 0 }, '-=0.4');
    };

    render() {
      const footerNav = this.state.footerMenu.map((element, index) => (
        <li key={index} className="footer__nav__item">
          <Link to={`/${element.slug}`}>{element.title}</Link>
        </li>
      ));

      const Footer = () => (
        <footer className="footer">
          <div className="footer--wrapper">
            <ul className="footer__nav">{footerNav}</ul>
            <div className="footer__copyrights">
              <div className="footer__copyrights-wrapper">
                <img
                  className="footer__copyrights__logo"
                  src={logoWhite}
                  alt="logo"
                />
                <span className="footer__copyrights__text">
                  <span>©</span>2018 William James
                </span>
              </div>
              <Link className="footer__copyrights__link" to="/privacy-policy">
                <span>Privacy Policy</span>
              </Link>
            </div>
          </div>
          <div
            style={{
              textAlign: 'center',
              fontSize: '14px',
              marginBottom: '30px',
              textTransform: 'none',
            }}
          >
            Liability limited by a scheme approved under Professional Standards
            Legislation
          </div>
        </footer>
      );

      const Header = () => (
        <header className="header">
          <div className="header__content">
            <Link to="/" className="logo">
              <img src={logo} alt="logo" />
              <img src={logoHover} className="logo--hover" alt="logo" />
            </Link>
            <div
              className={`hamburger ${this.state.navActive ? 'active' : ''} `}
              onMouseEnter={this.onMouseOver}
              onMouseLeave={this.onMouseLeave}
            >
              <input
                type="checkbox"
                id="trigger"
                onChange={this.navToggle}
                checked={this.state.navActive}
              />
              <label htmlFor="trigger">
                <span id="hamburger-first-line"></span>
                <span id="hamburger-second-line"></span>
                <span id="hamburger-third-line"></span>
              </label>
            </div>
            <Navigation
              nav={this.state.mainMenu}
              className={this.state.navActive ? 'navigation--open' : ''}
            />
          </div>
        </header>
      );

      return (
        <div className="app">
          <div id="grid"></div>
          <Route path="/" exact={false} component={Header} />
          <PropsRoute
            path="/"
            exact={true}
            component={Homepage}
            data={this.state.siteData.seo}
          />
          <PropsRoute
            path="/about-us"
            exact={true}
            component={About}
            data={findPageBySlug(this.state.siteData, 'about-us')}
          />
          <PropsRoute
            path="/contact-us"
            exact={true}
            component={Contact}
            data={findPageBySlug(this.state.siteData, 'contact-us')}
          />
          <PropsRoute
            path="/commercial-litigation"
            exact={true}
            component={Work}
            data={findPageBySlug(this.state.siteData, 'commercial-litigation')}
          />
          <PropsRoute
            path="/insolvency-and-restructuring"
            exact={true}
            component={Work}
            data={findPageBySlug(
              this.state.siteData,
              'insolvency-and-restructuring'
            )}
          />
          <PropsRoute
            path="/privacy-policy"
            exact={true}
            component={PrivacyPolicy}
            data={findPageBySlug(this.state.siteData, 'privacy-policy')}
          />
          <PropsRoute
            path="/people-and-culture"
            exact={false}
            component={People}
            data={findPageBySlug(this.state.siteData, 'people-and-culture')}
            team={getTheTeam(this.state.siteData)}
          />
          <PropsRoute
            path="/join-the-team"
            exact={true}
            component={Apply}
            data={findPageBySlug(this.state.siteData, 'join-the-team')}
          />
          <PropsRoute
            path="/news-overview"
            exact={true}
            component={NewsOverview}
            data={this.state.newsData}
          />
          <PropsRoute
            path="/news/:slug"
            exact={true}
            component={NewsSingle}
            data={this.state.newsData}
          />
          <PropsRoute
            path="/join-the-team/thank-you"
            exact={true}
            component={ApplicationSent}
          />
          <Route path="/" exact={false} component={Footer} />
        </div>
      );
    }
  }
);

class App extends Component {
  render() {
    return (
      <Router>
        <AppWithRouter
          newsData={this.props.newsData}
          siteData={this.props.siteData}
        />
      </Router>
    );
  }
}

export default App;
