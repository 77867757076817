import React, { Component } from 'react';

class ArrowIcon extends Component {
  render() {
    return (
      <svg width="5px" height="7px" viewBox="0 0 5 7" className={this.props.className}>
        <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="arrow" transform="translate(-10.000000, -1.000000)" fill="currentcolor">
            <g transform="translate(8.000000, 4.500000) rotate(-360.000000) translate(-8.000000, -4.500000) " id="Combined-Shape">
              <path d="M12,7 L9,7 L9,5 L12,5 L12,2 L14,2 L14,7 L12,7 Z" transform="translate(11.500000, 4.500000) rotate(-45.000000) translate(-11.500000, -4.500000) "></path>
            </g>
          </g>
        </g>
      </svg>
    );
  }
}

export default ArrowIcon;
