import React, { Component } from 'react';

class PenIcon extends Component {
  render() {
    return (
      <svg viewBox="0 0 22 31" className={this.props.className}>
        <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="contact-us" transform="translate(-62.000000, -58.000000)" fill="currentcolor">
            <g transform="translate(-157.000000, -55.000000)" id="icon">
              <g>
                <g transform="translate(212.000000, 113.000000)">
                  <path d="M8.43788673,0.00014090865 L27.6774213,0.00014090865 C28.1784165,0.000845451902 28.5834451,0.580008187 28.4112932,1.04927274 L25.7693821,8.18584145 L28.8393809,12.2482061 C29.0280111,12.4906817 29.0532302,12.8479063 28.9005329,13.114347 L18.736497,30.571495 C18.3910249,31.1256535 17.6384144,31.1639806 17.2442873,30.5591655 L7.104717,13.114206 C6.94859105,12.8500974 6.96878646,12.4930138 7.15364877,12.2480652 L10.2236604,8.18570054 L7.61843495,1.13453304 C7.38123775,0.47177273 7.71224138,0.00124704156 8.43788673,0 L8.43788673,0.00014090865 Z M26.5521729,1.5616483 L9.44086949,1.5616483 L11.6546929,7.53928211 L24.3383368,7.53928211 L26.5521729,1.5616483 Z M24.4973388,9.1007895 L11.4957033,9.1007895 L8.71923055,12.7727773 L17.2320672,27.4118651 L17.2199342,18.0306251 C16.1567168,17.6952273 15.3852295,16.6920282 15.3852295,15.5297784 C15.3852295,14.0993689 16.5685276,12.9191673 18.0027251,12.9191673 C19.4368726,12.9191673 20.6079253,14.0993689 20.6079253,15.5297784 C20.6079253,16.6880475 19.8430943,17.6918103 18.7855035,18.0306251 L18.7976472,27.3630755 L27.2860179,12.7727773 L24.4973388,9.1007895 Z M18.0026503,14.4806536 C17.4120309,14.4806536 16.9507233,14.9407098 16.9507233,15.5297784 C16.9507233,16.1188822 17.4191523,16.5911376 18.0026503,16.5911376 C18.5860852,16.5911376 19.0422684,16.1188822 19.0422684,15.5297784 C19.0422684,14.9407098 18.5932317,14.4806536 18.0026503,14.4806536 Z" id="Shape"></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    );
  }
}

export default PenIcon;
