import React, { Component } from 'react';
import withHelmet from '../../withHelmet';

// import lines from 'assets/images/map-guide.jpg';
import AnimatedLink from '../../atoms/animated-link/AnimatedLink';

import { GoogleApiWrapper, Map, Marker } from 'google-maps-react';

import ScrollMagic from 'scrollmagic';

import AnimationHelper from 'helpers/js/AnimationHelper';

const mapStyles = [
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [
      {
        color: '#e9e9e9'
      },
      {
        lightness: 17
      }
    ]
  },
  {
    featureType: 'landscape',
    elementType: 'geometry',
    stylers: [
      {
        color: '#f5f5f5'
      },
      {
        lightness: 20
      }
    ]
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#ffffff'
      },
      {
        lightness: 17
      }
    ]
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.stroke',
    stylers: [
      {
        color: '#ffffff'
      },
      {
        lightness: 29
      },
      {
        weight: 0.2
      }
    ]
  },
  {
    featureType: 'road.arterial',
    elementType: 'geometry',
    stylers: [
      {
        color: '#ffffff'
      },
      {
        lightness: 18
      }
    ]
  },
  {
    featureType: 'road.local',
    elementType: 'geometry',
    stylers: [
      {
        color: '#ffffff'
      },
      {
        lightness: 16
      }
    ]
  },
  {
    featureType: 'poi',
    elementType: 'geometry',
    stylers: [
      {
        color: '#f5f5f5'
      },
      {
        lightness: 21
      }
    ]
  },
  {
    featureType: 'poi.park',
    elementType: 'geometry',
    stylers: [
      {
        color: '#dedede'
      },
      {
        lightness: 21
      }
    ]
  },
  {
    elementType: 'labels.text.stroke',
    stylers: [
      {
        visibility: 'on'
      },
      {
        color: '#ffffff'
      },
      {
        lightness: 16
      }
    ]
  },
  {
    elementType: 'labels.text.fill',
    stylers: [
      {
        saturation: 36
      },
      {
        color: '#333333'
      },
      {
        lightness: 40
      }
    ]
  },
  {
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off'
      }
    ]
  },
  {
    featureType: 'transit',
    elementType: 'geometry',
    stylers: [
      {
        color: '#f2f2f2'
      },
      {
        lightness: 19
      }
    ]
  },
  {
    featureType: 'administrative',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#fefefe'
      },
      {
        lightness: 20
      }
    ]
  },
  {
    featureType: 'administrative',
    elementType: 'geometry.stroke',
    stylers: [
      {
        color: '#fefefe'
      },
      {
        lightness: 17
      },
      {
        weight: 1.2
      }
    ]
  }
];

class GoogleMapsContainer extends Component {
  render() {
    return (
      <Map
        styles={mapStyles}
        item
        google={this.props.google}
        zoom={16}
        initialCenter={{ lat: this.props.lat, lng: this.props.lng }}
      >
        <Marker
          position={{ lat: this.props.lat, lng: this.props.lng }}
          name={'William James'}
        />
      </Map>
    );
  }
}

const MyMap = GoogleApiWrapper({
  apiKey: 'AIzaSyAa8xo5pSju_XF-SGEHqZw__CTqXSEVBB4'
})(GoogleMapsContainer);

class Contact extends Component {
  componentDidMount() {
    AnimationHelper.init(new ScrollMagic.Controller(), this);
  }

  render() {
    const {
      phone,
      email,
      address,
      location_help_text,
      location = {}
    } = this.props.data;

    return (
      <section className="contact">
        <div className="background-lines">
          <div className="background-line background-line--1"></div>
          <div className="background-line background-line--2"></div>
          <div className="background-line background-line--3"></div>
        </div>
        <div className="contact__box animated-container">
          <div className="contact__map">
            <div className="map-desktop-bg"></div>
            <div className="contact__map__gmap" id="map">
              {location.lat && <MyMap lat={location.lat} lng={location.lng} />}
            </div>
          </div>
          <h1 className="contact__box__header with-transitions">
            Get in touch
          </h1>
          <div className="with-transitions">
            <div className="contact__box__title">phone</div>
            <div className="contact__box__value">
              <a href={`tel:${phone}`}>{phone}</a>
            </div>
            <div className="contact__box__title">email</div>
            <div className="contact__box__value">
              <a href={`mailto:${email}`}>{email}</a>
            </div>
            <div className="contact__box__title">address</div>
            <div className="contact__box__value">{address}</div>
          </div>
          {location.lat && (
            <AnimatedLink
              className="with-transitions"
              target="_blank"
              href={`https://www.google.com/maps/dir/?api=1&destination=${location.lat},${location.lng}`}
              text="directions"
            />
          )}
        </div>
        <div className="contact__directions">{location_help_text}</div>
      </section>
    );
  }
}

export default withHelmet(Contact);
