import TweenMax from 'gsap';

class BodyScroll {

  state = {
    frozen: false,
    scrollPosition: 0,
  }

  freeze() {
    this.state.frozen = true;
    this.state.scrollPosition = window.scrollY;

    TweenMax.set('#root', { marginTop: -this.state.scrollPosition });

    document.body.classList.add('block-scroll');
  }

  unfreeze() {
    this.state.frozen = false;

    document.body.classList.remove('block-scroll');

    TweenMax.set('#root', { marginTop: 0 });

    setTimeout(() => {
      TweenMax.set( window, { scrollTo: { y: this.state.scrollPosition }});
    }, 30);
  }

  toggleFreeze(setToFreeze) {
    if (setToFreeze) {
      this.freeze();
    } else {
      this.unfreeze();
    }
  }

}

export default BodyScroll;
