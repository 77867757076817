import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';

import BodyScroll from 'helpers/js/BodyScroll';


class Overlay extends Component {

  constructor(props) {
    super(props);

    this.bodyScroll = new BodyScroll();
  }

  findPersonById = (id) => {
    return this.props.data.find(element => {
      return element.ID === parseInt(id, 10);
    });
  }

  componentWillMount() {
    this.bodyScroll.freeze();
  }

  componentWillUnmount() {
    this.bodyScroll.unfreeze();
  }

  renderHTMLContent = (content) => {
    return { __html: content};
  }

  render() {

    const person = this.findPersonById(this.props.match.params.id);

    return (
      <div className="overlay">
        <div className="modal">
          <Link to="/people-and-culture" className="modal__close"></Link>
          <div className="modal__left">
            <div className="modal__image-container hide-on-mobile">
              <img className="modal__image" src={person.profile_picture.url} alt={person.title} />
            </div>
          </div>
          <div className="modal__right">
            <h1 className="modal__name">{person.title}</h1>
            <p className="modal__position">{person.designation}</p>
            <div className="modal__image-container hide-on-desktop">
              <img className="modal__image" src={person.profile_picture.url} alt={person.title} />
            </div>
            <p className="modal__description" dangerouslySetInnerHTML={this.renderHTMLContent(person.introduction)}></p>
            <div className="modal__contact">
              <div className="modal__contact__item"><span className="modal__contact__label">email</span><span className="modal__contact__value"><a
                href={`mailto:${person.email}`}>{person.email}</a></span></div>
              <div className="modal__contact__item"><span className="modal__contact__label">direct</span><span className="modal__contact__value"><a
                href={`tel:${person.phone}`}>{person.phone}</a></span></div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}


export default withRouter(Overlay);
