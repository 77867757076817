import { TimelineMax } from 'gsap';
import ScrollMagic from 'scrollmagic';
import { Sine } from 'gsap/EasePack';

import 'gsap/ScrollToPlugin';
import 'helpers/js/animation.gsap';

class AnimationHelper {

  static init(controller, node, findParagraphs) {

    const items = Array.from(document.getElementsByClassName('animated-container'));

    for (let element of items) {
      const timeline = new TimelineMax();

      const children = Array.from(element.querySelectorAll('.with-transitions, .p1, .ul1'));

      for (let child of children) {
        timeline.to(child, 0.4, { opacity: 1, y: 0, ease: Sine.easeOut }, `-=0.3`);
      }

      new ScrollMagic.Scene({ triggerElement: element, triggerHook: 0.9, reverse: false })
        .setTween(timeline)
        .addTo(controller);
    };
  }

}

export default AnimationHelper;
