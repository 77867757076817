import React, { Component } from 'react';
import { TweenMax } from 'gsap';
import withHelmet from '../../withHelmet';
import ScrollMagic from 'scrollmagic';
import moment from 'moment';

import AnimatedLink from 'components/atoms/animated-link/AnimatedLink';
import AnimationHelper from 'helpers/js/AnimationHelper';

class NewsSingle extends Component {

  componentDidMount() {
    this.controller = new ScrollMagic.Controller();
    AnimationHelper.init(this.controller, this);
  }

  componentWillUnmount() {
    this.controller.destroy();
  }

  getNextLink(slug) {
    const { data } = this.props;
    const index = data.findIndex(e => e.slug === slug);
    return index + 1 < data.length ? `/news/${data[index+1].slug}` : false;
  }

  getPrevLink(slug) {
    const { data } = this.props;
    const index = data.findIndex(e => e.slug === slug);
    return index - 1 > -1 ? `/news/${data[index-1].slug}` : false;
  }

  findNewsBySlug(slug) {
    return this.props.data.find(node => node.slug === slug);
  }

  getPostImage(news) {
    const media = (news && news._embedded['wp:featuredmedia']);
    if (media) {
      const image = media[0];
      return {
        src: image.media_details.sizes.full.source_url,
        alt: image.alt_text,
      }
    }

    return false;
  }

  render() {
    const slug = this.props.match.params.slug;
    const news = this.findNewsBySlug(slug);
    const prev = this.getPrevLink(slug);
    const next = this.getNextLink(slug);
    const image = this.getPostImage(news);
    return (
      <section className="news-details">
        {news ?
          <div>
            <AnimatedLink to="/news-overview" text="Back to news" className="news-details__back inverted" />
            <div className="news-details__content">
              {news._embedded['wp:featuredmedia'] &&
                <div className="news-details__image">
                  <img src={image.src} alt={image.alt} />
                </div>
              }
              <h1 dangerouslySetInnerHTML={{__html: news.title.rendered}} className="news-details__title"></h1>
              <div className="news-details__date-author">
                <div>{moment(news.date).format('Do MMMM YYYY')}</div>
                <div>{`written by ${news._embedded.author[0].name}`}</div>
              </div>
              <div dangerouslySetInnerHTML={{__html: news.content.rendered}} className="news-details__text"></div>
            </div>
            <div className="news-details__buttons">
              {prev && <AnimatedLink to={prev} text="Prev" className="inverted" />}
              {next && <AnimatedLink to={next} text="Next" />}
            </div>
          </div> :
          <div>News not found</div>
        }
      </section>
    )
  }
}

export default withHelmet(NewsSingle);
