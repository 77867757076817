import React, { Component } from 'react';

import AnimationHelper from 'helpers/js/AnimationHelper';
import ScrollMagic from 'scrollmagic';

class ApplicationSent extends Component {

  componentDidMount() {
    this.controller = new ScrollMagic.Controller();
    AnimationHelper.init(this.controller, this);
  }

  render() {
    return (
    <section className="apply apply-thank-you">
      <div className="animated-container">
        <div className="animated-container">
          <div className="apply__header with-transitions">
            <h1 className="with-transitions">Application form has been sent.</h1>
          </div>
        </div>
      </div>
    </section>
    )
  }
}

export default ApplicationSent;
