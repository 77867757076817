import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import contactUsTeaser from 'assets/images/contact-us-teaser.png';
import PenIcon from 'assets/icons/PenIcon';

import { TweenMax } from 'gsap';

class ContactTeaser extends Component {

  state = {
    lastScrollPosition: 0,
  }

  componentDidMount() {
    window.addEventListener('scroll', this.onScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll);
  }

  onScroll = (event) => {
    const position = window.scrollY;

    if (position > this.state.lastScrollPosition) {
      // rotate right
      TweenMax.set('.rotate-me', { rotation: '+=3' } );
    } else {
      // rotate left
      TweenMax.set('.rotate-me', { rotation: '-=3' } );
    }

    this.setState({
      lastScrollPosition: position,
    })
  }

  render() {

    return (
      <Link to="/contact-us" className="contact-teaser" {...this.props}>
        <PenIcon className="contact-teaser__pen" />
        <img src={contactUsTeaser} alt="contact us" className="rotate-me"/>
      </Link>
    );
  }
}

export default ContactTeaser;
