import React, { Component } from 'react';

import AnimationHelper from 'helpers/js/AnimationHelper';
import ScrollMagic from 'scrollmagic';

import withHelmet from '../../withHelmet';

import AnimatedLink from 'components/atoms/animated-link/AnimatedLink';
import MaskedInput from 'react-text-mask';
import { withRouter } from 'react-router-dom';

import axios from 'axios';

class Apply extends Component {

  state = {
    isSendingData: false,
    formSent: false,
    errorSending: false,
    file1: 'choose file',
    file2: 'choose file',
    file3: 'choose file',
  }

  renderHTMLContent = (content) => {
    return { __html: content};
  }

  componentDidMount() {
    this.controller = new ScrollMagic.Controller();
    AnimationHelper.init(this.controller, this);
  }

  componentWillUnmount() {
    this.controller.destroy();
  }

  handleSuccess = (event) => {

    this.setState({
      isSendingData: false,
      formSent: true,
    });

    this.props.history.push('/join-the-team/thank-you');
  }

  handleError = (event) => {
    this.setState({
      isSendingData: false,
      errorSending: true,
    });
  }

  file1Selected = (event) => {
    this.setState({
      file1: event.target.files.item(0).name
    })
  }

  file2Selected = (event) => {
    this.setState({
      file2: event.target.files.item(0).name
    })
  }

  file3Selected = (event) => {
    this.setState({
      file3: event.target.files.item(0).name
    })
  }

  handleSubmit = (event) => {
    event.preventDefault();

    const formData = new FormData(document.getElementById('apply_form'));

    this.setState({
      isSendingData: true,
    });

    axios({
      method: 'POST',
      url: '/send-form', // same a action attribute form value
      data: formData,
      config: { headers: {'Content-Type': 'multipart/form-data' }}
    }).then(this.handleSuccess).catch(this.handleError);
  }

  render() {

    const { raw_content, title } = this.props.data;

    return (
      <section className="apply">
        <div className="animated-container">
          <div className="apply__header with-transitions">
            <h1 className="with-transitions">{title}</h1>
            <p dangerouslySetInnerHTML={this.renderHTMLContent(raw_content)} className="with-transitions"></p>
          </div>
        </div>
        <div className="animated-container form-wrapper">
          <h2 className="form__title">start an application</h2>
          <p className="form__subtitle">If our values ring true then please get in touch with us. </p>
          {/*<AnimatedLink text="prefill with linkedin" className="orange form__prefill" onClick={this.triggerLinkedin}/>*/}
          <div className="">
            <form action="/send-form" id="apply_form" className="form" onSubmit={this.handleSubmit} ref={ form => (this.formEl = form)}>
              <fieldset disabled={this.state.isSendingData}>
              <input type="hidden" value="apply_form" name="form-name"/>
              <p className="bot-field-wrapper">
                <label>Don’t fill this out if you're human: <input name="bot-field"/></label>
              </p>
              <div className="form__section">
                <h3 className="form__section__title">Personal information</h3>
                <div className="form__section__content">
                  <div className="form__input-wrapper">
                    <input type="text" className="form__input" name="first_name" placeholder="First Name" required/>
                  </div>
                  <div className="form__input-wrapper">
                    <input type="text" className="form__input" name="last_name" placeholder="Last Name" required/>
                  </div>
                  <div className="form__input-wrapper">
                    <input type="email" className="form__input" name="email" placeholder="Email" required/>
                  </div>
                  <div className="form__input-wrapper">
                    <input type="text" className="form__input" name="phone" placeholder="Phone" required/>
                  </div>
                  <div className="form__input-wrapper">
                    <MaskedInput type="text" className="form__input" name="dob" placeholder="Date of birth dd-mm-yy" required mask={[/\d/, /\d/,'-',/\d/, /\d/,'-', /\d/, /\d/]}/>
                  </div>
                  <div className="form__input-wrapper">
                    <input type="text" className="form__input" name="postcode" placeholder="Postcode" required/>
                  </div>
                </div>
              </div>

              <div className="form__section">
                <h3 className="form__section__title">bachelors degree</h3>
                <div className="form__section__content">
                  <div className="form__input-wrapper">
                    <input type="text" className="form__input" name="ba_degree" placeholder="Degree"/>
                  </div>
                  <div className="form__input-wrapper">
                    <input type="text" className="form__input" name="ba_university" placeholder="University"/>
                  </div>
                  <div className="form__input-wrapper">
                    <input type="text" className="form__input" name="ba_year" placeholder="Year Graduated"/>
                  </div>
                  <div className="form__input-wrapper">
                    <input type="text" className="form__input" name="ba_honours" placeholder="Honours"/>
                  </div>
                </div>
              </div>


              <div className="form__section">
                <h3 className="form__section__title">postgraduate degree</h3>
                <div className="form__section__content">
                  <div className="form__input-wrapper">
                    <input type="text" className="form__input" name="pg_degree" placeholder="Degree"/>
                  </div>
                  <div className="form__input-wrapper">
                    <input type="text" className="form__input" name="pg_university" placeholder="University"/>
                  </div>
                  <div className="form__input-wrapper">
                    <input type="text" className="form__input" name="pg_year" placeholder="Year Graduated"/>
                  </div>
                  <div className="form__radio-group">
                    <p className="form__radio-group__title">Admitted to practice in NSW (LPAB)</p>
                    <div className="form__radio-wrapper">
                      <input type="radio" id="radio1" name="admitted_to_practice" value="complete"/>
                      <label htmlFor="radio1">Complete</label>
                    </div>
                    <div className="form__radio-wrapper">
                      <input type="radio" id="radio2" name="admitted_to_practice" value="in progress"/>
                      <label htmlFor="radio2">In progress</label>
                    </div>
                    <div className="form__radio-wrapper">
                      <input type="radio" id="radio3" name="admitted_to_practice" value="incomplete"/>
                      <label htmlFor="radio3">Incomplete</label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="form__section">
                <h3 className="form__section__title">recent experience</h3>
                <div className="form__section__content">
                  <div className="form__input-wrapper">
                    <input type="text" className="form__input" name="experience_1_role" placeholder="Role"/>
                  </div>
                  <div className="form__input-wrapper">
                    <input type="text" className="form__input" name="experience_1_company" placeholder="Company/Firm"/>
                  </div>
                  <div className="form__input-wrapper">
                    <input type="text" className="form__input" name="experience_1_from" placeholder="From"/>
                  </div>
                  <div className="form__input-wrapper">
                    <input type="text" className="form__input" name="experience_1_to" placeholder="To"/>
                  </div>
                  <div className="form__input-wrapper form__input-wrapper--full-width">
                    <input type="text" className="form__input" name="experience_1_description" placeholder="Description"/>
                  </div>
                </div>
              </div>

              <div className="form__section form__section--experience">
                <div className="form__section__content">
                  <div className="form__input-wrapper">
                    <input type="text" className="form__input" name="experience_2_role" placeholder="Role"/>
                  </div>
                  <div className="form__input-wrapper">
                    <input type="text" className="form__input" name="experience_2_company" placeholder="Company/Firm"/>
                  </div>
                  <div className="form__input-wrapper">
                    <input type="text" className="form__input" name="experience_2_from" placeholder="From"/>
                  </div>
                  <div className="form__input-wrapper">
                    <input type="text" className="form__input" name="experience_2_to" placeholder="To"/>
                  </div>
                  <div className="form__input-wrapper form__input-wrapper--full-width">
                    <input type="text" className="form__input" name="experience_2_description" placeholder="Description"/>
                  </div>
                </div>
              </div>

              <div className="form__section form__section--experience">
                <div className="form__section__content">
                  <div className="form__input-wrapper">
                    <input type="text" className="form__input" name="experience_3_role" placeholder="Role"/>
                  </div>
                  <div className="form__input-wrapper">
                    <input type="text" className="form__input" name="experience_3_company" placeholder="Company/Firm"/>
                  </div>
                  <div className="form__input-wrapper">
                    <input type="text" className="form__input" name="experience_3_from" placeholder="From"/>
                  </div>
                  <div className="form__input-wrapper">
                    <input type="text" className="form__input" name="experience_3_to" placeholder="To"/>
                  </div>
                  <div className="form__input-wrapper form__input-wrapper--full-width">
                    <input type="text" className="form__input" name="experience_3_description" placeholder="Description"/>
                  </div>
                </div>
              </div>

              <div className="form__section form__section--file">
                <div className="form__section__content">
                  <label htmlFor="resume" className="form__file">
                    <input type="file" className="form__file__input" name="resume" id="resume" onChange={this.file1Selected}/>
                    <span className="form__file__label">Upload resume</span>
                    <span className="form__file__value">{this.state.file1}</span>
                  </label>
                  <label htmlFor="cover_letter" className="form__file">
                    <input type="file" className="form__file__input" name="cover_letter" id="cover_letter" onChange={this.file2Selected}/>
                    <span className="form__file__label">Upload cover letter</span>
                    <span className="form__file__value">{this.state.file2}</span>
                  </label>
                  <label htmlFor="transscript" className="form__file">
                    <input type="file" className="form__file__input" name="transscript" id="transscript" onChange={this.file3Selected}/>
                    <span className="form__file__label">Upload transcript</span>
                    <span className="form__file__value">{this.state.file3}</span>
                  </label>
                </div>
              </div>


              <div className="form__submit-wrapper">
                <button className="form__submit" disabled={this.state.isSendingData || this.state.formSent}>
                  { !this.state.isSendingData && !this.state.formSent && !this.state.errorSending && <AnimatedLink text="submit application" className="orange" />}
                  { this.state.isSendingData && !this.state.formSent && <span className="form__sending-data-info">Sending data...</span>}
                  { !this.state.isSendingData && this.state.formSent && <span className="form__sending-data-info">Complete</span>}
                  { !this.state.isSendingData && this.state.errorSending && <span className="form__sending-data-info">There was an error</span>}
                </button>
              </div>
              </fieldset>
            </form>
          </div>
        </div>
      </section>
    )
  }
}

export default withRouter(withHelmet(Apply));
