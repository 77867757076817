import React, { Component } from 'react';
import withHelmet from '../../withHelmet';

class PrivacyPolicy extends Component {

  renderHTMLContent = (content) => {
    return { __html: content};
  }

  render() {

    return (
      <section className="privacy-policy">
        <h1 className="privacy-policy__title">{this.props.data.title}</h1>
        <div className="privacy-policy__content" dangerouslySetInnerHTML={this.renderHTMLContent(this.props.data.raw_content)}></div>
      </section>
    )
  }
}

export default withHelmet(PrivacyPolicy);
