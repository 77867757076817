import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Navigation extends Component {
  static defaultProps = {
    nav: [],
    contactInfo: [
      {
        title: 'phone',
        value: '<a href="tel:+61 02 9925 3222">(02) 9925 3222</a>'
      },
      {
        title: 'email',
        value: '<a href="mailto:info@wjlaw.com.au">info@wjlaw.com.au</a>'
      },
      {
        title: 'find us at',
        value:
          '<a href="https://www.google.com/maps/dir/?api=1&destination=level+11,2+Bligh+St+Sydney+NSW+2000" target="_blank">level 11, 2 Bligh St</a>'
      },
      {
        title: 'careers',
        value: `<a href="join-the-team">Apply today</a>`
      }
    ]
  };

  renderHTMLContent = content => {
    return { __html: content };
  };

  render() {
    const { nav, contactInfo } = this.props;

    const navigationItems = nav.map((element, index) => (
      <li className="navigation__item" key={index}>
        <Link to={`/${element.slug}`}>{element.title}</Link>
      </li>
    ));

    const contactItems = contactInfo.map((element, index) => (
      <li className="contact-info__item" key={index}>
        <div className="contact-info__item__title">{element.title}</div>
        <div
          className="contact-info__item__value"
          dangerouslySetInnerHTML={this.renderHTMLContent(element.value)}
        ></div>
      </li>
    ));

    return (
      <nav className={'navigation ' + this.props.className}>
        <div className="lines">
          <div className="line-1 line"></div>
          <div className="line-2 line"></div>
          <div className="line-3 line"></div>
          <div className="line-4 line"></div>
        </div>
        <div className="scroll-wrapper">
          <div className="inner-div">
            <ul className="navigation-wrapper">{navigationItems}</ul>
            <ul className="contact-info">{contactItems}</ul>
          </div>
        </div>
      </nav>
    );
  }
}

export default Navigation;
