import React, { Component } from 'react';
import { TweenMax } from 'gsap';
import ScrollMagic from 'scrollmagic';

import withHelmet from '../../withHelmet';

import ContactTeaser from 'components/atoms/contact-teaser/ContactTeaser';
import AnimatedLink from 'components/atoms/animated-link/AnimatedLink';


import AnimationHelper from 'helpers/js/AnimationHelper';

class Homepage extends Component {

  slidePage = () => {
    TweenMax.to( window, 0.5, { scrollTo: '#expertise' });
  }

  handleResize = (event) => {
    if (this.shouldEnableStickyTeaser()) {
      this.controller.addScene(this.contactTeaserScene);
    } else {
      this.controller.removeScene(this.contactTeaserScene);
    }
  }

  shouldEnableStickyTeaser = () => {
    return window.innerWidth > 1320;
  }

  componentDidMount() {
    this.controller = new ScrollMagic.Controller();
    AnimationHelper.init(this.controller, this);

    this.contactTeaserScene = new ScrollMagic.Scene({
      duration: 0, triggerElement: '#pin1'
    })
      .setPin('#pin1')
      .addTo(this.controller);

    this.scrollScene = new ScrollMagic.Scene({
      triggerHook: 1, offset: 300,
    })
      .setClassToggle('#scroll', 'hidden')
      .addTo(this.controller);

    if (this.shouldEnableStickyTeaser()) {
      this.controller.addScene(this.contactTeaserScene);
    } else {
      this.controller.removeScene(this.contactTeaserScene);
    }

    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    this.controller.destroy();
    window.removeEventListener('resize', this.handleResize);
  }

  render() {
    return (
      <main className="homepage">
        <div className="background-lines">
          <div className="background-line background-line--1"></div>
          <div className="background-line background-line--2"></div>
          <div className="background-line background-line--3"></div>
        </div>
        <div className="header-background">
          <div className="header-background__image"></div>
        </div>
        <div className="homepage__hero">
          <h1 className="title animated-container">
            <p className="title__1st with-transitions">We are</p>
            <p className="title__2nd with-transitions">William James</p>
            <p className="title__3rd with-transitions">Independent litigators</p>
          </h1>
          <section className="scroll-for-more" id="trigger">
            <AnimatedLink id="scroll" text="scroll for more" className={`vertical white`} onClick={this.slidePage} />
            <ContactTeaser id="pin1"/>
          </section>
        </div>
        <section className="expertise" id="expertise">
          <div className="animated-container">
            <h3 className="expertise__header with-transitions">Expertise</h3>
          </div>
          <div className="expertise__box-wrapper">
            <div className="expertise__box animated-container">
              <h4 className="expertise__box__title with-transitions">
                Commercial Litigation
              </h4>
              <p className="expertise__box__text with-transitions">
                <span className="intro-line"></span>
                <span>When we are in Court for our clients, we play to win.</span>
              </p>
              <AnimatedLink to="/commercial-litigation" text="Learn more" className="expertise__box__link with-transitions"/>
            </div>
            <div className="expertise__box animated-container">
              <h4 className="expertise__box__title with-transitions">
                insolvency & restructuring
              </h4>
              <p className="expertise__box__text with-transitions">
                <span className="intro-line"></span><span>Formal appointments, informal restructures or safe harbour - you need us in your corner.</span>
              </p>
              <AnimatedLink to="/insolvency-and-restructuring" text="Learn more" className="expertise__box__link with-transitions"/>
            </div>
          </div>
        </section>
        <div className="hires-spacer"></div>
        <section className="about-us animated-container">
          <div className="about-us__background"></div>
          <h3 className="about-us__title with-transitions">About us</h3>
          <p className="about-us__cta with-transitions">high energy, high performance lawyers</p>
          <AnimatedLink to="/about-us" className="about-us__link with-transitions" text="more about this"/>
        </section>
      </main>
    )
  }
}

export default withHelmet(Homepage);
