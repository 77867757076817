import React, { Component } from 'react';
import ScrollMagic from 'scrollmagic';

import withHelmet from '../../withHelmet';

import AnimatedLink from 'components/atoms/animated-link/AnimatedLink';
import ContactTeaser from 'components/atoms/contact-teaser/ContactTeaser';

import AnimationHelper from 'helpers/js/AnimationHelper';

class About extends Component {

  renderHTMLContent = (content) => {
    return { __html: content};
  }

  componentDidMount() {
    this.controller = new ScrollMagic.Controller();
    AnimationHelper.init(this.controller, this);

    this.contactTeaserScene = new ScrollMagic.Scene({
      duration: 0, triggerElement: '#pin1'
    })
      .setPin('#pin1')
      .addTo(this.controller);
  }

  render() {
    const { content = [] } = this.props.data;

    const cnt = content.map((element, index) => (
      <div className="about__content" key={index} dangerouslySetInnerHTML={this.renderHTMLContent(element.content_box)}></div>
    ));

    return (
      <div className="about-wrapper">
        <div className="contact-teaser-wrapper">
          <ContactTeaser id="pin1"/>
        </div>
        <section className="about">
          <div className="about__background"></div>
          <div className="background-lines">
            <div className="background-line background-line--1"></div>
            <div className="background-line background-line--2"></div>
            <div className="background-line background-line--3"></div>
          </div>
          <div className="animated-container">
            <p className="about__section-name with-transitions">About us</p>
            <h1 className="about__section-header with-transitions">{this.props.data.subtitle}</h1>
            <div className="about__content-wrapper with-transitions">
              {cnt}
            </div>
            <AnimatedLink to="/contact-us" text="contact us" />
          </div>
        </section>
      </div>
    )
  }
}

export default withHelmet(About);
